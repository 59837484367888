import Link from "next/link";
import styles from "../styles/404.module.scss";
import { ginger } from "../styles/fonts";

const ErrorPage = () => {
  return (
    <div className={styles.errorPage} style={ginger.style}>
      <div className={styles.imgContainer}>
        <img src={"/assets/404.png"}></img>
      </div>
      <h1 className={styles.heading}>Oops</h1>
      <p>{`You've got a roken link!`}</p>
      <Link
        href={"/"}
        style={{
          cursor: "pointer",
          textDecoration: "none",
          color: "black",
          fontWeight: "700",
        }}
        className={styles.redirectBtn}
      >
        Go back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
